import React, { useEffect } from 'react'
import * as R from 'ramda'
import * as yup from 'yup'
import { observer } from 'mobx-react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'

import { PlatformsRoute } from '../..'

import API from 'common/api'
import useStores from 'common/hook/useStore'
import { PlatformRequest, PlatformResponse } from 'common/api/platforms/platforms'
import { usePlatformQuery } from '../../PlatformsStore'
import { purgeEmptyStringProps } from 'utils/purgeEmptyStringProps'

import { Toolbar, Typography } from '@mui/material'
import Button, { ButtonVariant } from 'ui/Button/Button'
import TextField from 'ui/TextField/TextField'
import Footer from 'ui/Footer/Footer'

import Text from 'locale/strings'

import styles from './Platform.module.scss'

const schema = yup.object({
  name: yup
    .string()
    .matches(/^\S.*\S$|^\S$/, 'No leading or trailing spaces are allowed')
    .required('Name is required')
    .max(50, 'Name must be at most 30 characters'),
  offerCodePrefix: yup
    .string()
    .matches(/^\S.*\S$|^\S$/, 'No leading or trailing spaces are allowed')
    .max(10, 'Offer code prefix must be at most 10 characters'),
})

interface PlatformFormValues {
  id: string
  name: string
  offerCodePrefix?: string
}

const initialValues: PlatformFormValues = {
  id: '',
  name: '',
  offerCodePrefix: '',
}

interface ParamProps {
  id?: string
}

const Platform = () => {
  const { navigationStore, notificationsStore } = useStores()
  const { id = '' } = useParams<ParamProps>()
  const { data: platform } = usePlatformQuery(id)

  const initialPlatform = id !== '' ? platform : null

  const mutationPlatform = useMutation<PlatformResponse, Error, PlatformRequest>(
    (body: PlatformFormValues) => {
      if (initialPlatform) {
        return API.platforms.editPlatform({ ...body, id: initialPlatform.id })
      }

      return API.platforms.create(body)
    },
    {
      onSuccess: () => {
        navigationStore.goToPage(PlatformsRoute.path)

        initialPlatform
          ? notificationsStore.successNotification('Platform successfully edited')
          : notificationsStore.successNotification('Platform created successfully')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const handleSubmit = async (values: PlatformFormValues) => {
    const helperValues = { ...values }
    const prepareValues = purgeEmptyStringProps(helperValues)

    mutationPlatform.mutate(prepareValues)
  }

  const formik = useFormik<PlatformFormValues>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const { values, resetForm } = formik

  useEffect(() => {
    if (!R.isNil(initialPlatform)) {
      resetForm({
        values: {
          id: initialPlatform.id,
          name: initialPlatform.name,
          offerCodePrefix: initialPlatform.offerCodePrefix === null ? '' : initialPlatform.offerCodePrefix,
        },
      })
    }
  }, [initialPlatform, resetForm])

  return (
    <React.Fragment>
      <Toolbar className={styles.toolbar}>
        <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
          {!R.isNil(initialPlatform) ? Text.platforms.edit : Text.platforms.create}
        </Typography>
      </Toolbar>
      <div className={styles.container}>
        <div className={styles.form}>
          <TextField
            fullWidth
            data-test="name-field"
            label={Text.fields.name + ' *'}
            error={formik.errors.name}
            name="name"
            value={values.name}
            inputProps={{ maxLength: 50 }}
            onChange={formik.handleChange}
          />
          <TextField
            fullWidth
            data-test="offerCodePrefix-field"
            label={'Prefix'}
            error={formik.errors.offerCodePrefix}
            name="offerCodePrefix"
            value={values.offerCodePrefix}
            inputProps={{ maxLength: 10 }}
            onChange={formik.handleChange}
          />
        </div>
        <Footer>
          <Button
            data-test="cancel-button"
            variant={ButtonVariant.OUTLINED}
            onClick={() => navigationStore.goToPage(PlatformsRoute.path)}
          >
            {Text.common.cancel}
          </Button>
          <Button data-test="submit-button" loading={mutationPlatform.isLoading} onClick={formik.handleSubmit}>
            {Text.common.save}
          </Button>
        </Footer>
      </div>
    </React.Fragment>
  )
}

export default observer(Platform)
