/* eslint-disable jsx-control-statements/jsx-for-require-each */
import { useEffect } from 'react'
import * as R from 'ramda'
import { observer } from 'mobx-react'

import { CompaniesCreateRoute, CompaniesEditRoute } from '../..'

import useStores from 'common/hook/useStore'
import { MainRoute } from 'main'

import Text from 'locale/strings'
import { truncateText } from 'common/utils'
import { FilterFields } from 'common/constants'

import Pagination from 'ui/Pagination/Pagination'
import Button from 'ui/Button/Button'
import Table from 'ui/Table/Table'
import Spinner from 'ui/Spinner/Spinner'
import SectionFilters from 'ui/SectionFilters/SectionFilters'

import CompanyTableInfo from './CompanyTableInfo'

import CompaniesStore, { useAllCompaniesQuery } from '../../../companies/CompaniesStore'

import styles from './CompaniesTable.module.scss'

interface CompanyProps {
  companiesStore: CompaniesStore
}

const CompaniesTable = ({ companiesStore }: CompanyProps) => {
  const { navigationStore } = useStores()
  const { data, status } = useAllCompaniesQuery(companiesStore)
  const companies = data?.items ?? []
  const numberOfPages = data?.pageCount ?? 10

  useEffect(() => {
    if (R.isNil(companies)) {
      navigationStore.goToPage(MainRoute.path)
    }
  })

  const handleCreateClick = () => {
    navigationStore.goToPage(CompaniesCreateRoute.path)
  }

  const handleEditClick = (id: string) => {
    navigationStore.goToPage(CompaniesEditRoute.getUrl({ id: id }))
  }

  const columns = ['Id', 'Name', 'Platform', 'Actions']

  const dataTable = companies?.map((company: any) => [
    company.id,
    truncateText(company.name),
    truncateText(company?.platform?.name ?? '/'),
  ])

  const dataTableAdditionalInfo = companies?.map((comp: any) => {
    if (comp.apps === undefined) {
      return ['No available apps for this company']
    }

    if (comp.apps !== undefined) {
      return comp.apps?.map((app: any) => app.name)
    }

    return ''
  })

  const handleChangePage = (pageNumber: number, recordsNumber: number) => {
    companiesStore.changePage(pageNumber, recordsNumber)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button data-test="create-button" onClick={handleCreateClick}>
          {Text.navigation.createCompany}
        </Button>
      </div>

      <SectionFilters
        sectionStore={companiesStore}
        filters={[FilterFields.ID, FilterFields.NAME, FilterFields.PLATFORM]}
      />
      <div className={styles.tableWrapper}>
        {status === 'loading' ? (
          <Spinner />
        ) : (
          <Table
            hideDelete
            columns={columns}
            data={dataTable}
            dataAdditionalInfo={dataTableAdditionalInfo}
            additionalDataComponent={(props) => <CompanyTableInfo {...props} />}
            onEditClick={handleEditClick}
          />
        )}

        {companies.length > 0 && (
          <div>
            <div className={styles.paginationContainer}>
              <Pagination
                size={companiesStore.pageSize}
                page={companiesStore.page}
                totalPages={numberOfPages}
                onChange={handleChangePage}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(CompaniesTable)
