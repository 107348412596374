/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import API from 'common/api'

import { useAppsQuery } from 'apps/ApplicationStore'
import { useStynglistQuery } from '../../StynglistsStore'
import { useStyngsQuery, useRoyaltyFreeStyngsQuery, useStyngSourceTypesQuery } from 'styngs/StyngsStore'

import { StynglistsRoute } from '../../index'
import useStores from 'common/hook/useStore'

import { Toolbar, Typography, Box, Divider } from '@mui/material'
import Text from 'locale/strings'
import Button, { ButtonVariant } from 'ui/Button/Button'
import SearchBar from 'ui/SearchBar/SearchBar'
import TextField from 'ui/TextField/TextField'
import Select from 'ui/Select/Select'
import ImageUploadField from 'ui/ImageUploadField/ImageUploadField'
import Footer from 'ui/Footer/Footer'

import FormStore from 'common/FormStore'
import { Styng, RoyaltyFreeStyng } from 'common/api/styng/styng'
import StyngItem from '../../CreateStynglist/StyngItem/StyngItem'
import StyngsFilter from '../../CreateStynglist/StyngsFilter'
import { EditStynglistRequest, EditStynglistResponse } from 'common/api/stynglist/stynglist'
import { defaultImg } from 'common/constants'
import useImage from 'common/hook/useImage'
import Spinner from 'ui/Spinner/Spinner'
import { OptionType } from 'common/api/common/common'

import { INITIAL_PAGE, ALL_RECORDS, ApiStatuses, toAppOptions, toSourceTypeOptions } from '../../../common/constants'

import styles from './stynglist.module.scss'

const schema = yup.object({
  title: yup.string().max(50, 'Title must be at most 50 characters').required('Title is required'),
})

interface StynglistFormValues {
  title: string
  applicationId: string
  sourceType: string
  imageId: Nullable<string>
}

const initialValues: StynglistFormValues = {
  title: '',
  applicationId: '',
  sourceType: '',
  imageId: null,
}

interface ParamProps {
  id?: string
}

const Stynglist = () => {
  const { navigationStore, notificationsStore, styngsStore, applicationStore } = useStores()
  const formStore = new FormStore()
  const { id = '' } = useParams<ParamProps>()
  const [filter, setFilter] = useState<string>('')
  const queryClient = useQueryClient()
  const [initialStyngs, setInitialStyngs] = useState<Styng[]>([])
  const { data: appsLoadMore, status: appsLoadMoreStatus } = useAppsQuery(applicationStore)
  const { data: styngSourceTypes } = useStyngSourceTypesQuery()
  const { data: stynglistData, status, refetch: styngRefetch } = useStynglistQuery(id)
  const { data: styngs } = useStyngsQuery(styngsStore)
  const { data: styngsRoyaltyFree } = useRoyaltyFreeStyngsQuery(styngsStore)

  const [styngsList, setStyngsList] = useState<Styng[] | RoyaltyFreeStyng[]>([])
  const [currentApp, setCurrentApp] = useState<string>('')
  const [sourceType, setSourceType] = useState<string>('')
  const [currentStyng, setCurrentStyng] = useState<Styng | RoyaltyFreeStyng | null>(null)

  const [applicationsData, setApplicationsData] = useState<OptionType[]>([])
  const [sourceTypeOptions, setSourceTypeOptions] = useState<OptionType[]>([])

  applicationStore.changePage(INITIAL_PAGE, ALL_RECORDS)

  useEffect(() => {
    if (sourceType === 'LSR') {
      setStyngsList([...(styngs?.items ?? [])])
    } else {
      setStyngsList([...(styngsRoyaltyFree?.items ?? [])])
    }
  }, [styngs, styngsRoyaltyFree])

  useEffect(() => {
    if (typeof styngSourceTypes?.styngSourceTypes === 'undefined') {
      return
    }

    const allStyngSourceTypes = styngSourceTypes?.styngSourceTypes

    setSourceTypeOptions(allStyngSourceTypes?.map(toSourceTypeOptions) ?? [])
    setSourceType(allStyngSourceTypes[0].type ?? '')
    setFieldValue('sourceType', allStyngSourceTypes[0].type ?? '')
  }, [styngSourceTypes])

  useEffect(() => {
    const allItems = appsLoadMore?.apps

    setApplicationsData(allItems?.map(toAppOptions) ?? [])
  }, [appsLoadMore])

  const prepareStyng = () => {
    const stynglistDataImageId = stynglistData?.imageId
    const styngi = stynglistData?.styngs.filter((item) => item.imageId === stynglistDataImageId)

    if (styngi) {
      setCurrentStyng(styngi?.[0])
    }
  }

  React.useEffect(() => prepareStyng(), [stynglistData])

  const initialStynglist = id !== '' ? stynglistData : null

  const mutationStynglist = useMutation<EditStynglistResponse, Error, EditStynglistRequest>(
    (body: EditStynglistRequest) => {
      if (!R.isNil(initialStynglist)) {
        return API.stynglist.put({ ...body, id: initialStynglist?.id })
      }

      return API.stynglist.create(body)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stynglists')
        navigationStore.goToPage(StynglistsRoute.path)

        !R.isNil(initialStynglist)
          ? notificationsStore.successNotification('Stynglists successfully edited')
          : notificationsStore.successNotification('Stynglists successfully added')
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const hangleSetImage = (imageId: string) => {
    setCurrentStyng(null)
    formik.setFieldValue('imageId', imageId)
  }

  const handleSubmit = (values: StynglistFormValues) => {
    const body = {
      title: values.title.trim(),
      imageId: values.imageId,
      applicationId: values.applicationId === '' ? null : values.applicationId,
      sourceType: values.sourceType === '' ? null : values.sourceType,
      styngs: R.map(R.prop('id'), initialStyngs),
    }

    mutationStynglist.mutate(body)
  }

  const formik = useFormik<StynglistFormValues>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const { values, setFieldValue } = formik
  const { data } = useImage(values.imageId ?? '')

  const imageStynglist = data?.url ?? defaultImg

  useEffect(() => {
    if (!R.isNil(initialStynglist)) {
      formik.resetForm({
        values: {
          title: initialStynglist.name,
          applicationId: initialStynglist.applicationId ?? '',
          sourceType: initialStynglist.sourceType ?? '',
          imageId: initialStynglist.imageId,
        },
      })

      setInitialStyngs(initialStynglist?.styngs)
      setCurrentApp(initialStynglist.applicationId ?? '')
      setSourceType(initialStynglist.sourceType ?? '')
    }
  }, [initialStynglist])

  const handleClickStyng = (styng: Styng) => {
    //logic to prevent adding same styngs to the list
    let itemExist = false

    initialStyngs.map((e) => {
      if (e.id === styng.id) {
        return (itemExist = true)
      }

      return null
    })

    if (itemExist) {
      notificationsStore.infoNotification('Styng is already added to the list', 2500)

      return
    }

    setInitialStyngs([...initialStyngs, styng])
  }

  const handleSelectStyng = (styng: Styng) => {
    setCurrentStyng(styng)
    formik.setFieldValue('imageId', styng.imageId)
  }

  const handleDeleteStyng = (id: string) => {
    const newStyngs = initialStyngs.filter((item) => item.id !== id)

    setInitialStyngs(newStyngs)
  }

  const handleSearch = (value: string) => {
    styngsStore.handleNameSearch(value)
  }

  const handleCancel = () => {
    navigationStore.goToPage(StynglistsRoute.path)
  }

  const handleAppChange = (app: string) => {
    setCurrentApp(app)
    setFieldValue('applicationId', app)
  }

  const handleSourceTypeChange = (sourceType: string) => {
    setSourceType(sourceType)
    setFieldValue('sourceType', sourceType)
    setInitialStyngs([])
    setFilter('')
    styngsStore.handleNameSearch('')
  }

  useEffect(() => {
    if (sourceType === 'LSR') {
      setStyngsList([...(styngs?.items ?? [])])
    } else {
      setStyngsList([...(styngsRoyaltyFree?.items ?? [])])
    }
  }, [sourceType])

  return (
    <Box>
      {status === ApiStatuses.LOADING && appsLoadMoreStatus === ApiStatuses.LOADING ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Toolbar className={styles.toolbar}>
            <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
              {!R.isNil(initialStynglist) ? 'Edit stynglist' : 'Add stynglist'}
            </Typography>
          </Toolbar>
          <div className={styles.container}>
            <div className={styles.row}>
              <div className={styles.inputWrapper}>
                <TextField
                  fullWidth
                  data-test="title-field"
                  label={Text.page.stynglists.create.stynglistTitle}
                  name="title"
                  value={values.title}
                  error={formik.errors.title}
                  onChange={formik.handleChange}
                />
                <Select
                  fullWidth
                  clear
                  data-test="application-field"
                  label={'Application'}
                  value={values.applicationId}
                  options={applicationsData}
                  className={styles.select}
                  error={formik.errors.applicationId}
                  onChange={handleAppChange}
                />
                <Select
                  fullWidth
                  disabled={!R.isNil(initialStynglist)}
                  data-test="source-type-field"
                  label={'Source type'}
                  value={values.sourceType}
                  options={sourceTypeOptions}
                  className={styles.select}
                  onChange={handleSourceTypeChange}
                />
              </div>
              <div className={styles.uploadImage}>
                <ImageUploadField image={imageStynglist} hangleSetImage={hangleSetImage} />
              </div>
            </div>

            <Divider variant="middle" />

            <div className={styles.subContainer}>
              <div className={styles.leftColumn}>
                <SearchBar
                  label={Text.page.stynglists.searchPlaceholder}
                  filter={filter}
                  onChangeFilter={setFilter}
                  onSearch={handleSearch}
                >
                  <StyngsFilter styngs={styngsList} onClickStyng={handleClickStyng} />
                </SearchBar>
              </div>
              <div className={styles.rightColumn}>
                {initialStyngs.length !== 0 &&
                  sourceType === 'LSR' &&
                  initialStyngs.map((styng) => {
                    return (
                      <StyngItem
                        appId={currentApp}
                        key={styng.id}
                        styng={styng}
                        refetch={styngRefetch}
                        selectedId={currentStyng?.id}
                        onSelect={handleSelectStyng}
                        onDelete={handleDeleteStyng}
                      />
                    )
                  })}
                {initialStyngs.length !== 0 &&
                  sourceType === 'ROYALTY_FREE' &&
                  initialStyngs.map((styng) => {
                    return (
                      <StyngItem
                        withoutGenres
                        appId={currentApp}
                        key={styng.id}
                        styng={styng}
                        refetch={styngRefetch}
                        selectedId={currentStyng?.id}
                        onSelect={handleSelectStyng}
                        onDelete={handleDeleteStyng}
                      />
                    )
                  })}
                {initialStyngs.length === 0 && <p className={styles.noSelectedStyng}>There is no selected Styng</p>}
              </div>
            </div>
            <Footer>
              <Button data-test="cancel-button" variant={ButtonVariant.OUTLINED} onClick={handleCancel}>
                {Text.common.cancel}
              </Button>
              <Button data-test="submit-button" loading={formStore.loading} onClick={formik.handleSubmit}>
                {Text.common.save}
              </Button>
            </Footer>
          </div>
        </React.Fragment>
      )}
    </Box>
  )
}

export default observer(Stynglist)
