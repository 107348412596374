import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'
import { useMutation } from 'react-query'

import useStores from '../../common/hook/useStore'

import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import SearchBar from '../../ui/SearchBar/SearchBar'
import Button, { ButtonVariant } from '../../ui/Button/Button'
import Pagination from '../../ui/Pagination/Pagination'

import { Duration } from 'luxon'
import { AddTrackRequest } from '../../common/api/track/track'
import API from '../../common/api'
import { useListPlaylistsQuery } from '../../playlists/PlaylistsStore'
import { Playlist } from '../../common/api/playlist/playlist'
import { ApiStatuses, PlaylistTypes, INITIAL_PAGE } from '../../common/constants'
import Text from '../../locale/strings'

import Spinner from '../../ui/Spinner/Spinner'

import styles from './trackModal.module.scss'

interface StyngProps {
  open: boolean
  trackMediaNetIds: any[]
  handleClose: () => void
}

const TrackModal = ({ open, trackMediaNetIds, handleClose }: StyngProps) => {
  const { playlistsStore, notificationsStore } = useStores()

  const { data, status, refetch } = useListPlaylistsQuery(playlistsStore)
  const [numberOfPages, setNumberOfPages] = useState<number>(INITIAL_PAGE)

  const [filter, setFilter] = useState('')

  useEffect(() => {
    if (playlistsStore.handleTypeSearch) {
      playlistsStore?.handleTypeSearch(PlaylistTypes.LICENSED)
    }
  }, [])

  let result = data?.playlists ?? []

  const [playlists, setPlaylists] = useState<Playlist[]>([])

  const listContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    return () => {
      playlistsStore.changePage(1)
    }
  }, [trackMediaNetIds])

  useEffect(() => {
    result = data?.playlists ?? []

    if (data?.pagesCount) {
      setNumberOfPages(data.pagesCount)
    }

    setPlaylists(result)
  }, [data])

  const mutationAddTrackToPlaylist = useMutation<Playlist, Error, AddTrackRequest>(
    (ids) => {
      return API.playlist.addTrackToPlaylist(ids)
    },
    {
      onSuccess: () => {
        refetch()

        notificationsStore.successNotification('Track successfully added to playlist')

        handleClose()
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const handleClickPlaylist = (id: string) => {
    mutationAddTrackToPlaylist.mutate({ playlistId: id, trackIds: trackMediaNetIds })
  }

  const handleSearchPlaylists = (value: string) => {
    setFilter(value)

    if (value === '') {
      return setPlaylists(result)
    }

    const searchResults = result?.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))

    setPlaylists(searchResults)
  }

  const handleChangePage = (pageNumber: number, recordsNumber: number) => {
    playlistsStore.changePage(pageNumber, recordsNumber)
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onBackdropClick={handleClose}
    >
      <Box>
        <div className={styles.container}>
          <div className={styles.header}>{'Choose playlist'}</div>
          <div className={styles.searchWrapper}>
            <SearchBar modalView filter={filter} onChangeFilter={handleSearchPlaylists} onSearch={() => {}} />
          </div>

          <div className={styles.listHeader}>
            <p>Name</p>
            <p>Duration</p>
            <p>Tracks</p>
            <p>App</p>
          </div>
          <div className={styles.list} ref={listContainer}>
            {status === ApiStatuses.LOADING ? (
              <Spinner />
            ) : (
              <React.Fragment>
                {playlists.map((item: Playlist) => (
                  <p
                    role="button"
                    key={item.id}
                    className={styles.listItem}
                    onClick={() => handleClickPlaylist(item.id)}
                  >
                    <span>{item.name}</span>
                    <span>{Duration.fromISO(item.duration).toFormat('h:m:s')}</span>
                    <span>{item.numberOfTracks}</span>
                    <span>{item.appNames}</span>
                  </p>
                ))}
              </React.Fragment>
            )}
          </div>
          {playlists.length > 0 && (
            <div className={styles.paginationContainer}>
              <Pagination
                size={playlistsStore.pageSize}
                page={playlistsStore.page}
                totalPages={numberOfPages}
                onChange={handleChangePage}
              />
            </div>
          )}

          <div className={styles.submitContainer}>
            <Button variant={ButtonVariant.OUTLINED} onClick={handleClose}>
              {Text.common.cancel}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default thread(TrackModal, [observer])
