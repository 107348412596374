import { useObserver } from 'mobx-react'
import cx from 'classnames'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'

import Button, { ButtonColor, ButtonSize } from '../../ui/Button/Button'
import Text from '../../ui/Text/Text'

import TextContent from '../../locale/strings'
import useStores from '../../common/hook/useStore'
import { AdminMode } from '../../common/constants'

import { Application } from '../../common/api/app/app'
import { Company } from '../../common/api/company/company'

import styles from './header.module.scss'

interface HeaderProps {
  onLogout: () => void
  companies: Company[]
  companyName: string
  applications: Application[]
  applicationName: string
  onChangeApp: (value: string) => void
  profileCredentials: string
}

const Header = ({ onLogout, profileCredentials }: HeaderProps) => {
  const { applicationStore } = useStores()

  const headerClassName = cx(styles.header, {
    [styles.admin]: applicationStore.adminMode === AdminMode.ON,
  })

  return useObserver(() => (
    <AppBar position="fixed" className={headerClassName}>
      <Toolbar variant="dense" className={styles.content}>
        <div className={styles.leftSide}>
          <div className={styles.logo} />
        </div>
        <Text className={styles.userCredentials}>{profileCredentials}</Text>
        <Button size={ButtonSize.SMALL} color={ButtonColor.WARNING} onClick={onLogout}>
          {TextContent.common.logout}
        </Button>
      </Toolbar>
    </AppBar>
  ))
}

export default Header
