import React, { useState, useEffect } from 'react'

import TablePagination from '@mui/material/TablePagination'

interface PaginationProps {
  page: number
  totalPages: number
  size?: number
  onChange: (currentPageNumber: number, currentRecordsNumber: number) => void
}

const Pagination = ({ page, totalPages, size, onChange }: PaginationProps) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(30)

  useEffect(() => {
    if (size) {
      setRowsPerPage(size)
    }
  }, [size])

  const handleChangePage = (event: unknown, newPage: number) => {
    onChange(newPage, rowsPerPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value))
    onChange(1, parseInt(event.target.value))
  }

  return (
    <TablePagination
      sx={{ display: 'flex', flexDirection: 'row' }}
      rowsPerPageOptions={[30, 50, 100]}
      component="div"
      rowsPerPage={rowsPerPage}
      count={totalPages}
      page={page}
      labelRowsPerPage={'Rows to Display'}
      labelDisplayedRows={({ from, to, count }) => `${page} of ${totalPages}`}
      nextIconButtonProps={{ disabled: page >= totalPages }}
      backIconButtonProps={{ disabled: page === 1 }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}

export default Pagination
