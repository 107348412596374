/* eslint-disable jsx-control-statements/jsx-for-require-each */
import { useState } from 'react'
import { observer } from 'mobx-react'

import useStores from 'common/hook/useStore'

import { useMutation } from 'react-query'

import { CreateSoundboardRoute, EditSoundboardRoute } from '../..'

import SectionFilters from 'ui/SectionFilters/SectionFilters'
import Table from 'ui/Table/Table'
import Pagination from 'ui/Pagination/Pagination'
import Button from 'ui/Button/Button'
import Spinner from 'ui/Spinner/Spinner'
import DeleteModal from 'ui/DeleteModal/DeleteModal'
import Text from 'locale/strings'
import { truncateText } from 'common/utils'
import { FilterFields, ApiStatuses } from 'common/constants'
import API from 'common/api'

import SoundboardStore, { useSoundboardListQuery } from './../../SoundboardStore'

import styles from './SoundboardTable.module.scss'

interface SoundboardStoreProps {
  soundboardStore: SoundboardStore
}

const SoundboardTable = ({ soundboardStore }: SoundboardStoreProps) => {
  const { notificationsStore, navigationStore } = useStores()
  const { data, status, refetch } = useSoundboardListQuery(soundboardStore)
  const soundboards = data?.items ?? []
  const numberOfPages = data?.pagesCount ?? 10
  const [selectedSoundboard, setSelectedSoundboard] = useState<any | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const onDeleteMutation = useMutation(
    () => {
      return API.soundboard.deleteSoundboard({ soundboardId: selectedSoundboard?.id })
    },
    {
      onSuccess: () => {
        setShowDeleteModal(false)

        notificationsStore.successNotification('Soundboard is successfully deleted')
        refetch()
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const handleCreateRadioAd = () => {
    navigationStore.goToPage(CreateSoundboardRoute.path)
  }

  const handleEditClick = (id: string) => {
    navigationStore.goToPage(EditSoundboardRoute.getUrl({ id: id }))
  }

  const handleChangePage = (pageNumber: number, recordsNumber: number) => {
    soundboardStore.changePage(pageNumber, recordsNumber)
  }

  const columns = [
    'Image',
    'Id',
    'Name',
    'Subscription Price',
    'Styng Price',
    'Number of Styngs',
    'Source Type',
    'Actions',
  ]

  const dataTable = soundboards.map((soundboard: any) => [
    soundboard.imageUrl,
    soundboard.id,
    truncateText(soundboard.name),
    soundboard.subscriptionPrice,
    soundboard.styngPrice,
    soundboard.numerOfStyngs,
    soundboard.sourceType,
  ])

  const handleDeleteClick = (id: string) => {
    const soundboard = soundboards.filter((soundboard: any) => soundboard.id === id)

    setSelectedSoundboard(soundboard[0])
    setShowDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedSoundboard(null)
  }

  const handleDeleteSoundboard = () => {
    onDeleteMutation.mutate()
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button data-test="create-button" onClick={() => handleCreateRadioAd()}>
          {Text.navigation.createSoundboard}
        </Button>
      </div>

      <SectionFilters sectionStore={soundboardStore} filters={[FilterFields.ID, FilterFields.NAME]} />
      <div className={styles.tableWrapper}>
        {status === ApiStatuses.LOADING ? (
          <Spinner />
        ) : (
          <Table columns={columns} data={dataTable} onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} />
        )}

        {soundboards.length > 0 && (
          <div>
            <div className={styles.paginationContainer}>
              <Pagination
                size={soundboardStore.pageSize}
                page={soundboardStore.page}
                totalPages={numberOfPages}
                onChange={handleChangePage}
              />
            </div>
          </div>
        )}
      </div>

      {showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          headerContent={Text.page.soundboard.deleteAdTitle}
          handleClose={handleCloseDeleteModal}
          text={`Do you want to delete soundboard ${truncateText(selectedSoundboard.name)}?`}
          handleSubmit={handleDeleteSoundboard}
        />
      )}
    </div>
  )
}

export default observer(SoundboardTable)
