import strings from '../locale/strings'
import Route, { ParametrizedRoute } from 'lib/routing/Route'

import Companies from './pages/Companies'
import Company from './pages/Company/Company'

import BusinessIcon from '@mui/icons-material/Business'

export const CompaniesRoute: Route = {
  path: '/companies',
  name: strings.navigation.companies,
  icon: <BusinessIcon />,
  component: Companies,
}

export const CompaniesCreateRoute: Route = {
  path: '/companies/create',
  name: strings.navigation.createCompany,
  exact: true,
  component: Company,
}

export const CompaniesEditRoute: ParametrizedRoute = {
  path: '/companies/edit/:id',
  name: strings.navigation.editCompany,
  exact: true,
  component: Company,
  getUrl: ({ id }) => `/companies/edit/${id}`,
}
