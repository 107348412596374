import strings from 'locale/strings'
import Route, { ParametrizedRoute } from 'lib/routing/Route'
import UsersPage from './pages/UsersPage'
import User from './pages/User/User'
import GroupIcon from '@mui/icons-material/Group'

export const UsersRoute: Route = {
  path: '/users',
  exact: true,
  name: strings.navigation.users,
  icon: <GroupIcon />,
  component: UsersPage,
}

export const UserCreateRoute: Route = {
  path: '/users/create',
  name: strings.navigation.createUser,
  exact: true,
  component: User,
}

export const UserEditRoute: ParametrizedRoute = {
  path: '/users/edit/:id',
  name: strings.navigation.editUser,
  exact: true,
  component: User,
  getUrl: ({ id }) => `/users/edit/${id}`,
}
