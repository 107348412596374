import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'
import cx from 'classnames'

import { useSidebar } from 'utils/sidebarContext'

import styles from './Content.module.scss'

interface ContentProps {
  children: any
}

const Content = ({ children }: ContentProps) => {
  const { isOpen } = useSidebar()

  const contentClassName = cx(styles.content, {
    [styles.open]: isOpen ? 'open' : '',
  })

  return (
    <div id="mainLayoutContainer" className={contentClassName}>
      {children}
    </div>
  )
}

export default thread(Content, [observer])
