/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import * as yup from 'yup'
import { useFormik } from 'formik'

import { useTiersQuery } from '../../PlaylistsStore'

import {
  Tab,
  Toolbar,
  Box,
  Collapse,
  Checkbox,
  Chip,
  TextField as MuiTextField,
  Typography,
  IconButton,
} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

import TextField from '../../../ui/TextField/TextField'
import Spinner from '../../../ui/Spinner/Spinner'
import Button, { ButtonVariant } from '../../../ui/Button/Button'
import Footer from '../../../ui/Footer/Footer'

import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import useStores from '../../../common/hook/useStore'

import FormStore from '../../../common/FormStore'
import API from '../../../common/api'
import { PlaylistTypes, EditPlaylistTabs, PlaylistTiers } from '../../../common/constants'
import { Territory, TerritoryAvailability } from 'common/api/territory/territory'

import Text from '../../../locale/strings'
import PlaylistMetadata from './../../components/PlaylistMetadata/PlaylistMetadata'
import LicensedPlaylistTable from '../../components/LicensedPlaylistTable/LicensedPlaylistTable'
import RoyaltyFreePlaylistTable from '../../components/RoyaltyFreePlaylistTable/RoyaltyFreePlaylistTable'
import RadioAdGroup from 'radioAdGroups/pages/RadioAdGroup/RadioAdGroup'
import { PlaylistsRoute } from '../../index'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import styles from './EditPlaylist.module.scss'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const schema = yup.object({
  name: yup
    .string()
    .matches(/^\S.*\S$|^\S$/, 'No leading or trailing spaces are allowed')
    .required('Name is required')
    .max(70, 'Name must be at most 70 characters'),
})

interface EditPlaylistFormValues {
  name: string
  platformId: string
  appIds: any[]
  availability: object
}

interface App {
  appId: string
  applicationName: string
  isBound: boolean
}

const initialValues: EditPlaylistFormValues = {
  name: '',
  platformId: '',
  appIds: [],
  availability: {},
}

interface PlaylistProps {
  formStore: FormStore
  playlistId: string
  playlistBasicInfoData: any
  playlistBasicInfoStatus: string
  playlistBasicInfoRefetch: any
}

const EditPlaylist = ({
  formStore,
  playlistId,
  playlistBasicInfoData,
  playlistBasicInfoStatus,
  playlistBasicInfoRefetch,
}: PlaylistProps) => {
  const { navigationStore, notificationsStore } = useStores()
  const { data: tiersData } = useTiersQuery()
  const [tabValue, setTabValue] = useState(EditPlaylistTabs.GENERAL_INFORMATION_TAB)
  // const { isOpen } = useSidebar()

  useEffect(() => {
    const currentTab = localStorage.getItem('setTab')

    if (currentTab !== null) {
      setTabValue(currentTab as EditPlaylistTabs)
      localStorage.removeItem('setTab')
    }
  }, [])

  const [selectedApps, setSelectedApps] = useState<App[]>([])
  const [availableApps, setAvailableApps] = useState<App[]>([])

  const [selectedCountriesArray, setSelectedCountriesArray] = useState<Territory[]>([])
  const [availableCountriesArray, setAvailableCountriesArray] = useState<Territory[]>([])

  const [selectedTiersForEditPlaylist, setSelectedTiersForEditPlaylist] = useState<any[]>([])
  const [allTiersForEditPlaylist, setAllTiersForEditPlaylist] = useState<any[]>([])

  const [metadata, setMetadata] = useState<any>()
  const [newMetadata, setNewMetadata] = useState<any>()
  const [openMetadata, setOpenMetadata] = React.useState<boolean>(false)

  useEffect(() => {
    setAllTiersForEditPlaylist(tiersData?.tiers ?? [])
  }, [tiersData])

  useEffect(() => {
    if (typeof playlistBasicInfoData !== 'undefined') {
      const correctFormatForAppAutocomplete: App[] = []
      const initialSelectedApps: App[] = []

      const correctFormatForAutocomplete: Territory[] = []
      const initialSelectedValues: Territory[] = []

      playlistBasicInfoData.applications.forEach(function (app: any) {
        correctFormatForAppAutocomplete.push(app)

        if (app.isBound) {
          initialSelectedApps.push(app)
        }
      })

      setAvailableApps(correctFormatForAppAutocomplete)
      setSelectedApps(initialSelectedApps)

      playlistBasicInfoData.availability.forEach(function (territory: TerritoryAvailability) {
        correctFormatForAutocomplete.push(territory)

        if (territory.isAvailable) {
          initialSelectedValues.push(territory)
        }
      })

      setAvailableCountriesArray(correctFormatForAutocomplete)
      setSelectedCountriesArray(initialSelectedValues)

      setSelectedTiersForEditPlaylist(playlistBasicInfoData.tiers)

      setMetadata(playlistBasicInfoData.metadata)
    }
  }, [playlistBasicInfoData])

  const EditPlaylistMutation = useMutation<any, Error, any>(
    (body: any) => {
      return API.playlist.updateBasicInfo(body)
    },
    {
      onSuccess: () => {
        resetForm()
        navigationStore.goToPage(PlaylistsRoute.path)
        notificationsStore.successNotification('Playlist successfully edited')
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const handleSubmit = async (formValues: EditPlaylistFormValues) => {
    formStore.handleSubmit(() => {
      if (formValues.name === '') {
        return
      }

      EditPlaylistMutation.mutate({
        playlistId: playlistId,
        name: formValues.name.trim(),
        platformId: playlistBasicInfoData.platformId,
        appIds: selectedApps.map((item) => item.appId),
        selectedCountries: selectedCountriesArray.map((item) => item.territoryCode),
        tiers: selectedTiersForEditPlaylist.map((item) => item.id),
        genres: newMetadata.genres.map((item: any) => item.name),
        moods: newMetadata.moods.map((item: any) => item.item),
        bpms: newMetadata.bpms.map((item: any) => item.item),
        instruments: newMetadata.instruments.map((item: any) => item.item),
        builds: newMetadata.builds.map((item: any) => item.item),
        vocals: newMetadata.vocals.map((item: any) => item.item),
        durations: newMetadata.durations.map((item: any) => item.item),
        themes: newMetadata.themes.map((item: any) => item.item),
        featuredArtist: newMetadata.featuredArtist,
      })
    })
  }

  const formik = useFormik<EditPlaylistFormValues>({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  const { values, resetForm } = formik

  useEffect(() => {
    resetForm({
      values: {
        name: playlistBasicInfoData.name,
        platformId: '',
        appIds: [],
        availability: {},
      },
    })
  }, [playlistBasicInfoData, resetForm])

  const handleTabChange = (_: React.SyntheticEvent, newValue: EditPlaylistTabs) => {
    setTabValue(newValue)

    if (newValue === EditPlaylistTabs.GENERAL_INFORMATION_TAB) {
      playlistBasicInfoRefetch()
    }
  }

  const handleAvailabilityChange = (_: React.SyntheticEvent, value: Territory[]) => {
    setSelectedCountriesArray(value)
  }

  const handleDeleteCountry = (value: any) => {
    setSelectedCountriesArray((countryArray) =>
      countryArray.filter((countryOption) => countryOption.territoryCode !== value),
    )
  }

  const handleAppChange = (_: React.SyntheticEvent, value: any) => {
    setSelectedApps(value)
  }

  const handleDeleteApp = (value: any) => {
    setSelectedApps((countryArray) => countryArray.filter((appOption) => appOption.appId !== value))
  }

  const handleTierChangeForEditPlaylist = (_: React.SyntheticEvent, value: any) => {
    setSelectedTiersForEditPlaylist(value)
  }

  const handleDeleteTierForEditPlaylist = (value: any) => {
    setSelectedTiersForEditPlaylist((countryArray) => countryArray.filter((appOption) => appOption.id !== value))
  }

  const renderGeneralInfo = () => {
    return (
      <div className={styles.generalInfo}>
        <div className={styles.header}>{Text.page.app.generalInformation}</div>
        <div className={styles.leftSide}>
          <TextField
            fullWidth
            data-test="name-field"
            className={styles.textField}
            label={Text.page.playlists.create.fields.name}
            name="name"
            value={values.name}
            error={formik.errors.name}
            onChange={formik.handleChange}
          />
          <TextField
            fullWidth
            disabled
            className={styles.textField}
            label={'Platform *'}
            value={playlistBasicInfoData.platformName}
            error={formik.errors.platformId}
          />
          <div className={styles.autocompleteWrapper}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              data-test="applications-field"
              id="applications"
              className={styles.autocompleteField}
              value={selectedApps}
              options={availableApps}
              getOptionLabel={(option: any) => option.applicationName}
              renderTags={() => null}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.applicationName}
                </li>
              )}
              renderInput={(params) => <MuiTextField {...params} label={'Application *'} placeholder="Search" />}
              onChange={handleAppChange}
            />
            <Box className={styles.autocompleteFieldValues}>
              {selectedApps.map((option) => (
                <Chip
                  className={styles.autocompleteFieldValue}
                  key={option.appId}
                  label={option.applicationName}
                  onDelete={() => handleDeleteApp(option.appId)}
                />
              ))}
            </Box>
          </div>
          {Object.keys(availableCountriesArray).length !== 0 ? (
            <div className={styles.autocompleteWrapper}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                data-test="available-countries-field"
                id="availableCountries"
                className={styles.autocompleteField}
                isOptionEqualToValue={(option, value) => option.territoryCode === value.territoryCode}
                value={selectedCountriesArray}
                options={availableCountriesArray}
                getOptionLabel={(option: Territory) => option.territoryCode}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.territoryName}
                  </li>
                )}
                renderTags={() => null}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label={
                      playlistBasicInfoData.type.type === PlaylistTypes.LICENSED
                        ? Text.page.playlists.edit.subsetSectionTitle
                        : Text.page.playlists.edit.blockedSubsetSectionTitle
                    }
                    placeholder="Search"
                  />
                )}
                onChange={handleAvailabilityChange}
              />
              <Box className={styles.autocompleteFieldValues}>
                {selectedCountriesArray.map((option) => (
                  <Chip
                    className={styles.autocompleteFieldValue}
                    key={option.territoryCode}
                    label={option.territoryName}
                    onDelete={() => handleDeleteCountry(option.territoryCode)}
                  />
                ))}
              </Box>
            </div>
          ) : (
            <div className={styles.autocompleteWrapper}>
              <p>{Text.page.playlists.edit.noSubset}</p>
            </div>
          )}
        </div>
        <div className={styles.rightSide}>
          <TextField
            disabled
            fullWidth
            data-test="playlist-type-application"
            label={'Playlist type'}
            className={styles.textField}
            value={playlistBasicInfoData.type.description}
          />
          <TextField
            disabled
            fullWidth
            data-test="monetization-type-application"
            label={'Monetization type '}
            className={styles.textField}
            value={playlistBasicInfoData.monetizationType.description}
          />
          <div className={styles.autocompleteWrapper}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              data-test="edit-playlist-tiers-field"
              id="tiers"
              disabled={playlistBasicInfoData.monetizationType.type !== PlaylistTiers.PREMIUM}
              className={styles.autocompleteField}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={selectedTiersForEditPlaylist}
              options={allTiersForEditPlaylist}
              getOptionLabel={(option: any) => option.name}
              renderTags={() => null}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.name}
                </li>
              )}
              renderInput={(params) => <MuiTextField {...params} label={'Tiers'} placeholder="Search" />}
              onChange={handleTierChangeForEditPlaylist}
            />
            <Box className={styles.autocompleteFieldValues}>
              {selectedTiersForEditPlaylist.map((option) => (
                <Chip
                  className={styles.autocompleteFieldValue}
                  key={option.id}
                  label={option.name}
                  onDelete={() => handleDeleteTierForEditPlaylist(option.id)}
                />
              ))}
            </Box>
          </div>
        </div>
        <div className={styles.header}>
          {'Metadata'}
          <IconButton aria-label="expand row" size="small" onClick={() => setOpenMetadata(!openMetadata)}>
            {openMetadata ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
        <Collapse className={styles.collapse} timeout="auto" in={openMetadata}>
          <PlaylistMetadata metadata={metadata} setNewMetadata={setNewMetadata} />
        </Collapse>

        <Footer>
          <Button
            data-test="cancel-button"
            variant={ButtonVariant.OUTLINED}
            onClick={() => navigationStore.goToPage(PlaylistsRoute.path)}
          >
            {Text.common.cancel}
          </Button>
          <Button data-test="submit-button" className={styles.saveButton} onClick={formik.handleSubmit}>
            {Text.common.save}
          </Button>
        </Footer>
      </div>
    )
  }

  return (
    <div className={styles.playlistEdit}>
      <Toolbar className={styles.toolbar}>
        <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
          Edit playlist
        </Typography>
      </Toolbar>
      <Box>
        {playlistBasicInfoStatus === 'loading' ? (
          <Spinner />
        ) : (
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList aria-label="Playlist page tabs" onChange={handleTabChange}>
                <Tab label="General" value={EditPlaylistTabs.GENERAL_INFORMATION_TAB} />
                <Tab label="Tracks" value={EditPlaylistTabs.PLAYLIST_TRACKS_TAB} />
                {playlistBasicInfoData.monetizationType.type === 'INTERNAL_AD_FUNDED' && (
                  <Tab label="Ads Management" value={EditPlaylistTabs.ADS_MANAGEMENT} />
                )}
              </TabList>
            </Box>
            <TabPanel value={EditPlaylistTabs.GENERAL_INFORMATION_TAB}>{renderGeneralInfo()}</TabPanel>
            <TabPanel value={EditPlaylistTabs.PLAYLIST_TRACKS_TAB}>
              {playlistBasicInfoData.type.type === PlaylistTypes.LICENSED ? (
                <LicensedPlaylistTable platformId={playlistBasicInfoData.platformId} playlistId={playlistId} />
              ) : (
                <RoyaltyFreePlaylistTable platformId={playlistBasicInfoData.platformId} playlistId={playlistId} />
              )}
            </TabPanel>
            <TabPanel value={EditPlaylistTabs.ADS_MANAGEMENT}>
              <RadioAdGroup playlistBasicInfoData={playlistBasicInfoData} />
            </TabPanel>
          </TabContext>
        )}
      </Box>
    </div>
  )
}

export default EditPlaylist
