import Route from 'lib/routing/Route'
import TracksForStyngsPage from './TracksForStyngsPage'
import strings from '../../locale/strings'

import AlbumIcon from '@mui/icons-material/Album'

export const TracksForStyngsRoute: Route = {
  path: '/tracks-for-styngs',
  name: strings.navigation.tracksForStyngs,
  exact: true,
  icon: <AlbumIcon />,
  component: TracksForStyngsPage,
}
