import strings from '../../locale/strings'
import Route, { ParametrizedRoute } from 'lib/routing/Route'
import AppsPage from './AppsPage'
import AppInfoPage from './AppInfoPage'
import AppGeneralFormPage from './AppGeneralFormPage/AppGeneralFormPage'

import AppsIcon from '@mui/icons-material/Apps'

export const AppsRoute: Route = {
  path: '/apps',
  name: strings.navigation.apps,
  exact: true,
  icon: <AppsIcon />,
  component: AppsPage,
}

export const AppInfoRoute: ParametrizedRoute = {
  path: '/apps/appinfo/:appId',
  name: strings.navigation.app,
  exact: true,
  component: AppInfoPage,
  getUrl: ({ appId }) => `/apps/appinfo/${appId}`,
}

export const AppGeneralFormNewRoute: Route = {
  path: '/apps/new',
  name: strings.navigation.createApp,
  exact: true,
  component: AppGeneralFormPage,
}

export const AppGeneralFormEditRoute: ParametrizedRoute = {
  path: '/apps/edit/:appId',
  name: strings.navigation.editApp,
  exact: true,
  component: AppGeneralFormPage,
  getUrl: ({ appId }) => `/apps/edit/${appId}`,
}
