import strings from 'locale/strings'
import Route, { ParametrizedRoute } from 'lib/routing/Route'
import StynglistsPage from './pages/StynglistsPage'
import Stynglist from './pages/Stynglist/Stynglist'

import BookmarksIcon from '@mui/icons-material/Bookmarks'

export const StynglistsRoute: Route = {
  path: '/stynglists',
  name: strings.navigation.stynglists,
  exact: true,
  icon: <BookmarksIcon />,
  component: StynglistsPage,
}

export const CreateStynglistRoute: Route = {
  path: '/stynglists/create',
  name: strings.navigation.createStynglist,
  exact: true,
  component: Stynglist,
}

export const EditStynglistRoute: ParametrizedRoute = {
  path: '/stynglists/edit/:id',
  name: strings.navigation.editStynglist,
  exact: true,
  component: Stynglist,
  getUrl: ({ id }) => `/stynglists/edit/${id}`,
}
