/* eslint-disable @typescript-eslint/indent */
import React, { Dispatch, useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import { TextField as MuiTextField, Chip, Autocomplete, Checkbox } from '@mui/material'
import LoadingComponent from 'ui/Loading/LoadingPage'
import TextField from 'ui/TextField/TextField'

import Text from 'locale/strings'

// features query
import { usePlaylistMetadataQuery } from 'playlists/PlaylistsStore'
import { useGenresTracksQuery } from 'tracks/filterHooks'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import styles from './PlaylistMetadata.module.scss'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface FeaturesProps {
  setNewMetadata: Dispatch<React.SetStateAction<any>>
  metadata?: any
}

const PlaylistMetadata = ({ metadata, setNewMetadata }: FeaturesProps) => {
  const [selectedGenres, setSelectedGenres] = useState<any[]>([])
  const [allGenres, setAllGenres] = useState<any[]>([])

  const [selectedMoods, setSelectedMoods] = useState<any[]>([])
  const [allMoods, setAllMoods] = useState<any[]>([])

  const [selectedBpms, setSelectedBpms] = useState<any[]>([])
  const [allBpms, setAllBpms] = useState<any[]>([])

  const [selectedInstruments, setSelectedInstruments] = useState<any[]>([])
  const [allInstruments, setAllInstruments] = useState<any[]>([])

  const [selectedBuilds, setSelectedBuilds] = useState<any[]>([])
  const [allBuilds, setAllBuilds] = useState<any[]>([])

  const [selectedVocals, setSelectedVocals] = useState<any[]>([])
  const [allVocals, setAllVocals] = useState<any[]>([])

  const [selectedDurations, setSelectedDurations] = useState<any[]>([])
  const [allDurations, setAllDurations] = useState<any[]>([])

  const [selectedThemes, setSelectedThemes] = useState<any[]>([])
  const [allThemes, setAllThemes] = useState<any[]>([])

  const [selectedFeaturedArtist, setSelectedFeaturedArtist] = useState<string>('')

  const { data: genresData, status: GenresStatus } = useGenresTracksQuery()
  const { data: playlistMetadata, status: PlaylistMetadataStatus } = usePlaylistMetadataQuery()

  useEffect(() => {
    if (genresData) {
      setAllGenres(genresData?.genres)
    }
  }, [genresData])

  useEffect(() => {
    if (playlistMetadata) {
      setAllMoods(playlistMetadata.moods)
      setAllBpms(playlistMetadata.bpms)
      setAllInstruments(playlistMetadata.instruments)
      setAllBuilds(playlistMetadata.builds)
      setAllVocals(playlistMetadata.vocals)
      setAllDurations(playlistMetadata.durations)
      setAllThemes(playlistMetadata.themes)
    }
  }, [playlistMetadata])

  useEffect(() => {
    if (metadata) {
      setSelectedGenres(metadata.genres || [])
      setSelectedMoods(metadata.moods || [])
      setSelectedBpms(metadata.bpms || [])
      setSelectedInstruments(metadata.instruments || [])
      setSelectedBuilds(metadata.builds || [])
      setSelectedVocals(metadata.vocals || [])
      setSelectedDurations(metadata.durations || [])
      setSelectedThemes(metadata.themes || [])
      setSelectedFeaturedArtist(metadata.featuredArtist || '')
    }
  }, [metadata])

  useEffect(() => {
    setNewMetadata({
      genres: selectedGenres,
      moods: selectedMoods,
      bpms: selectedBpms,
      instruments: selectedInstruments,
      builds: selectedBuilds,
      vocals: selectedVocals,
      durations: selectedDurations,
      themes: selectedThemes,
      featuredArtist: selectedFeaturedArtist,
    })
  }, [
    selectedGenres,
    selectedMoods,
    selectedBpms,
    selectedInstruments,
    selectedBuilds,
    selectedVocals,
    selectedDurations,
    selectedThemes,
    selectedFeaturedArtist,
  ])

  // GENRES
  const handleGenresChange = (_: React.SyntheticEvent, value: any) => {
    setSelectedGenres(value)
  }

  const handleDeleteGenres = (value: any) => {
    setSelectedGenres((genre: any) => genre.filter((genreOption: any) => genreOption.id !== value))
  }

  // MOODS
  const handleMoodsChange = (_: React.SyntheticEvent, value: any) => {
    setSelectedMoods(value)
  }

  const handleDeleteMoods = (value: any) => {
    setSelectedMoods((moods: any) => moods.filter((moodOption: any) => moodOption.item !== value))
  }

  // BPM
  const handleBpmsChange = (_: React.SyntheticEvent, value: any) => {
    setSelectedBpms(value)
  }

  const handleDeleteBpms = (value: any) => {
    setSelectedBpms((bpm) => bpm.filter((bpmOption) => bpmOption.item !== value))
  }

  // INSTRUMENTS
  const handleInstrumentsChange = (_: React.SyntheticEvent, value: any) => {
    setSelectedInstruments(value)
  }

  const handleDeleteInstruments = (value: any) => {
    setSelectedInstruments((instrument) => instrument.filter((instrumentOption) => instrumentOption.item !== value))
  }

  // BUILDS
  const handleBuildsChange = (_: React.SyntheticEvent, value: any) => {
    setSelectedBuilds(value)
  }

  const handleDeleteBuilds = (value: any) => {
    setSelectedBuilds((build) => build.filter((buildOption) => buildOption.item !== value))
  }

  // VOCALS
  const handleVocalsChange = (_: React.SyntheticEvent, value: any) => {
    setSelectedVocals(value)
  }

  const handleDeleteVocals = (value: any) => {
    setSelectedVocals((vocal) => vocal.filter((vocalOption) => vocalOption.item !== value))
  }

  // DURATIONS
  const handleDurationsChange = (_: React.SyntheticEvent, value: any) => {
    setSelectedDurations(value)
  }

  const handleDeleteDurations = (value: any) => {
    setSelectedDurations((duration) => duration.filter((durationOption) => durationOption.item !== value))
  }

  // THEMES
  const handleThemesChange = (_: React.SyntheticEvent, value: any) => {
    setSelectedThemes(value)
  }

  const handleDeleteThemes = (value: any) => {
    setSelectedThemes((theme) => theme.filter((themeOption) => themeOption.item !== value))
  }

  // FEATURED ARTIST
  const handleFeaturedArtist = (event: any) => {
    setSelectedFeaturedArtist(event.target.value)
  }

  const renderPlaylistMetadata = () => {
    return (
      <React.Fragment>
        <div className={styles.autocompleteWrapper}>
          <Autocomplete
            fullWidth
            multiple
            disableCloseOnSelect
            data-test="playlist-genre-field"
            id="genres"
            className={styles.autocompleteField}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedGenres}
            options={allGenres}
            getOptionLabel={(option: any) => option.name}
            renderTags={() => null}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.name}
              </li>
            )}
            renderInput={(params) => <MuiTextField {...params} label={'Genres'} placeholder="Search" />}
            onChange={handleGenresChange}
          />
          <Box className={styles.autocompleteFieldValues}>
            {selectedGenres.map((option) => (
              <Chip
                className={styles.autocompleteFieldValue}
                key={option.id}
                label={option.name}
                onDelete={() => handleDeleteGenres(option.id)}
              />
            ))}
          </Box>
        </div>
        <div className={styles.autocompleteWrapper}>
          <Autocomplete
            fullWidth
            multiple
            disableCloseOnSelect
            data-test="playlist-mood-field"
            id="moods"
            className={styles.autocompleteField}
            isOptionEqualToValue={(option, value) => option.item === value.item}
            value={selectedMoods}
            options={allMoods}
            getOptionLabel={(option: any) => option.description}
            renderTags={() => null}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.description}
              </li>
            )}
            renderInput={(params) => <MuiTextField {...params} label={'Moods'} placeholder="Search" />}
            onChange={handleMoodsChange}
          />
          <Box className={styles.autocompleteFieldValues}>
            {selectedMoods.map((option) => (
              <Chip
                className={styles.autocompleteFieldValue}
                key={option.item}
                label={option.description}
                onDelete={() => handleDeleteMoods(option.item)}
              />
            ))}
          </Box>
        </div>
        <div className={styles.autocompleteWrapper}>
          <Autocomplete
            fullWidth
            multiple
            disableCloseOnSelect
            data-test="playlist-bpm-field"
            id="bpms"
            className={styles.autocompleteField}
            isOptionEqualToValue={(option, value) => option.item === value.item}
            value={selectedBpms}
            options={allBpms}
            getOptionLabel={(option: any) => option.description}
            renderTags={() => null}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.description}
              </li>
            )}
            renderInput={(params) => <MuiTextField {...params} label={'BPMS'} placeholder="Search" />}
            onChange={handleBpmsChange}
          />
          <Box className={styles.autocompleteFieldValues}>
            {selectedBpms.map((option) => (
              <Chip
                className={styles.autocompleteFieldValue}
                key={option.item}
                label={option.description}
                onDelete={() => handleDeleteBpms(option.item)}
              />
            ))}
          </Box>
        </div>
        <div className={styles.autocompleteWrapper}>
          <Autocomplete
            fullWidth
            multiple
            disableCloseOnSelect
            data-test="playlist-instrument-field"
            id="instruments"
            className={styles.autocompleteField}
            isOptionEqualToValue={(option, value) => option.item === value.item}
            value={selectedInstruments}
            options={allInstruments}
            getOptionLabel={(option: any) => option.description}
            renderTags={() => null}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.description}
              </li>
            )}
            renderInput={(params) => <MuiTextField {...params} label={'Instruments'} placeholder="Search" />}
            onChange={handleInstrumentsChange}
          />
          <Box className={styles.autocompleteFieldValues}>
            {selectedInstruments.map((option) => (
              <Chip
                className={styles.autocompleteFieldValue}
                key={option.item}
                label={option.description}
                onDelete={() => handleDeleteInstruments(option.item)}
              />
            ))}
          </Box>
        </div>
        <div className={styles.autocompleteWrapper}>
          <Autocomplete
            fullWidth
            multiple
            disableCloseOnSelect
            data-test="playlist-build-field"
            id="builds"
            className={styles.autocompleteField}
            isOptionEqualToValue={(option, value) => option.item === value.item}
            value={selectedBuilds}
            options={allBuilds}
            getOptionLabel={(option: any) => option.description}
            renderTags={() => null}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.description}
              </li>
            )}
            renderInput={(params) => <MuiTextField {...params} label={'Builds'} placeholder="Search" />}
            onChange={handleBuildsChange}
          />
          <Box className={styles.autocompleteFieldValues}>
            {selectedBuilds.map((option) => (
              <Chip
                className={styles.autocompleteFieldValue}
                key={option.item}
                label={option.description}
                onDelete={() => handleDeleteBuilds(option.item)}
              />
            ))}
          </Box>
        </div>
        <div className={styles.autocompleteWrapper}>
          <Autocomplete
            fullWidth
            multiple
            disableCloseOnSelect
            data-test="playlist-vocal-field"
            id="vocals"
            className={styles.autocompleteField}
            isOptionEqualToValue={(option, value) => option.item === value.item}
            value={selectedVocals}
            options={allVocals}
            getOptionLabel={(option: any) => option.description}
            renderTags={() => null}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.description}
              </li>
            )}
            renderInput={(params) => <MuiTextField {...params} label={'Vocals'} placeholder="Search" />}
            onChange={handleVocalsChange}
          />
          <Box className={styles.autocompleteFieldValues}>
            {selectedVocals.map((option) => (
              <Chip
                className={styles.autocompleteFieldValue}
                key={option.item}
                label={option.description}
                onDelete={() => handleDeleteVocals(option.item)}
              />
            ))}
          </Box>
        </div>
        <div className={styles.autocompleteWrapper}>
          <Autocomplete
            fullWidth
            multiple
            disableCloseOnSelect
            data-test="playlist-duration-field"
            id="durations"
            className={styles.autocompleteField}
            isOptionEqualToValue={(option, value) => option.item === value.item}
            value={selectedDurations}
            options={allDurations}
            getOptionLabel={(option: any) => option.description}
            renderTags={() => null}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.description}
              </li>
            )}
            renderInput={(params) => <MuiTextField {...params} label={'Durations'} placeholder="Search" />}
            onChange={handleDurationsChange}
          />
          <Box className={styles.autocompleteFieldValues}>
            {selectedDurations.map((option) => (
              <Chip
                className={styles.autocompleteFieldValue}
                key={option.item}
                label={option.description}
                onDelete={() => handleDeleteDurations(option.item)}
              />
            ))}
          </Box>
        </div>
        <div className={styles.autocompleteWrapper}>
          <Autocomplete
            fullWidth
            multiple
            disableCloseOnSelect
            data-test="playlist-theme-field"
            id="themes"
            className={styles.autocompleteField}
            isOptionEqualToValue={(option, value) => option.item === value.item}
            value={selectedThemes}
            options={allThemes}
            getOptionLabel={(option: any) => option.description}
            renderTags={() => null}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.description}
              </li>
            )}
            renderInput={(params) => <MuiTextField {...params} label={'Themes'} placeholder="Search" />}
            onChange={handleThemesChange}
          />
          <Box className={styles.autocompleteFieldValues}>
            {selectedThemes.map((option) => (
              <Chip
                className={styles.autocompleteFieldValue}
                key={option.item}
                label={option.description}
                onDelete={() => handleDeleteThemes(option.item)}
              />
            ))}
          </Box>
        </div>
        <TextField
          fullWidth
          data-test="featured-artist-field"
          className={styles.textField}
          label={'Featured Artist'}
          name="featured-artist"
          value={selectedFeaturedArtist}
          inputProps={{ maxLength: 100 }}
          onChange={(e) => handleFeaturedArtist(e)}
        />
      </React.Fragment>
    )
  }

  return (
    <Box className={styles.playlistMetadataContainer}>
      <Choose>
        <When condition={PlaylistMetadataStatus === 'loading' || GenresStatus === 'loading'}>
          <LoadingComponent />
        </When>
        <When condition={PlaylistMetadataStatus === 'success'}>{renderPlaylistMetadata()}</When>
        <When condition={PlaylistMetadataStatus === 'error'}>
          <div>{Text.errors.generalErrorMessage}</div>
        </When>
      </Choose>
    </Box>
  )
}

export default PlaylistMetadata
