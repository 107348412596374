import strings from 'locale/strings'
import Route, { ParametrizedRoute } from 'lib/routing/Route'

import StyngsPage from './pages/StyngsPage/StyngsPage'
import RoyaltyFreeStyng from './components/RoyaltyFreeStyng/RoyaltyFreeStyng'

import BookmarkIcon from '@mui/icons-material/Bookmark'

export const StyngsRoute: Route = {
  path: '/styngs',
  name: strings.navigation.styngs,
  exact: true,
  icon: <BookmarkIcon />,
  component: StyngsPage,
}

export const CreateRFStyngRoute: Route = {
  path: '/styngs/create/royalty-free',
  name: strings.navigation.createRFStyng,
  exact: true,
  component: RoyaltyFreeStyng,
}

export const EditRFStyngRoute: ParametrizedRoute = {
  path: '/styngs/edit/royalty-free/:id',
  name: strings.navigation.radioAds,
  exact: true,
  component: RoyaltyFreeStyng,
  getUrl: ({ id }) => `/styngs/edit/royalty-free/${id}`,
}
