import React from 'react'
import cx from 'classnames'

import { useSidebar } from 'utils/sidebarContext'

import styles from './Footer.module.scss'

interface FooterProps {
  children?: React.ReactNode
  className?: string
}

const Footer = ({ children, className = '' }: FooterProps) => {
  const { isOpen } = useSidebar()

  const footerStyles = cx(styles.submitContainer, className, {
    [styles.open]: isOpen ? 'open' : '',
  })

  return <div className={footerStyles}>{children}</div>
}

export default Footer
