import { observer } from 'mobx-react'

import PlatformsStore, { usePlatformListQuery } from '../../PlatformsStore'

import useStores from 'common/hook/useStore'
import { Platform } from 'common/api/platforms/platforms'

import Pagination from 'ui/Pagination/Pagination'
import Spinner from 'ui/Spinner/Spinner'
import Table from 'ui/Table/Table'
import Button from 'ui/Button/Button'
import SectionFilters from 'ui/SectionFilters/SectionFilters'

import Text from 'locale/strings'

import { PlatformCreateRoute, PlatformEditRoute } from '../..'

import { truncateText } from 'common/utils'
import { FilterFields, ApiStatuses } from 'common/constants'

import styles from './PlatformsTable.module.scss'

interface PlatformsTableProps {
  platformsStore: PlatformsStore
}

const PlatformsTable = ({ platformsStore }: PlatformsTableProps) => {
  const { navigationStore } = useStores()
  const { data, status } = usePlatformListQuery(platformsStore)
  const platforms = data?.items ?? []
  const numberOfPages = data?.pageCount ?? 10

  const columns = ['Id', 'Name', 'Prefix', 'Actions']

  const dataTable = platforms.map((platform: Platform) => [
    platform.id,
    truncateText(platform.name),
    platform.offerCodePrefix,
  ])

  const handleCreate = () => {
    navigationStore.goToPage(PlatformCreateRoute.path)
  }

  const handleEditClick = (id: string) => {
    navigationStore.goToPage(PlatformEditRoute.getUrl({ id: id }))
  }

  const handleChangePage = (pageNumber: number, recordsNumber: number) => {
    platformsStore.changePage(pageNumber, recordsNumber)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button data-test="create-button" onClick={() => handleCreate()}>
          {Text.platforms.create}
        </Button>
      </div>

      <SectionFilters
        sectionStore={platformsStore}
        filters={[FilterFields.ID, FilterFields.NAME, FilterFields.PREFIX]}
      />
      <div className={styles.tableWrapper}>
        {status === ApiStatuses.LOADING ? (
          <Spinner />
        ) : (
          <Table hideDelete columns={columns} data={dataTable} onEditClick={handleEditClick} />
        )}

        {platforms.length > 0 && (
          <div>
            <div className={styles.paginationContainer}>
              <Pagination
                size={platformsStore.pageSize}
                page={platformsStore.page}
                totalPages={numberOfPages}
                onChange={handleChangePage}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(PlatformsTable)
