/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useRef, useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { observer } from 'mobx-react'
import { Duration } from 'luxon'
import * as R from 'ramda'
import { thread } from 'lib/fn'
import { CSVLink } from 'react-csv'

import API from '../common/api'
import useStores from '../common/hook/useStore'
import { formatItemsForCsvData } from '../common/utils'
import { useSidebar } from 'utils/sidebarContext'

import Pagination from '../ui/Pagination/Pagination'
import SearchBar from '../ui/SearchBar/SearchBar'
import Button from 'ui/Button/Button'
import { Track, TrackDownloadValues } from '../common/api/track/track'
import TracksStore, { useTracksQuery } from './TracksStore'
import Table from '../ui/Table/Table'
import TrackModal from './TrackModal/TrackModal'
import DownloadTrackModal from './DownloadTrackModal/DownloadTrackModal'
import Spinner from '../ui/Spinner/Spinner'
import Text from '../locale/strings'
import TrackFilter from './TrackFilter/TrackFilter'

import { Identity } from '../common/api/common/common'

import cx from 'classnames'
import styles from './tracks.module.scss'

interface TracksProps {
  tracksStore: TracksStore
}

const Tracks = ({ tracksStore }: TracksProps) => {
  const { notificationsStore } = useStores()
  const [filter, setFilter] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([])
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [mainTableCheckboxValue, setMainTableCheckboxValue] = useState<boolean>(false)
  const { data, status } = useTracksQuery(tracksStore)
  const { isOpen } = useSidebar()

  const [loadingDataForCsv, setLoadingDataForCsv] = useState<boolean>(false)
  const csvLinkRef = useRef<any>(null)

  const [csvReport, setCsvReport] = useState<any>({
    data: [],
    headers: [],
    filename: '',
  })

  const tracks = data?.tracks ?? []

  const [showDownloadTrackModal, setShowDownloadTrackModal] = useState<boolean>(false)

  const [dataForTrackDownloadModal, setDataForTrackDownloadModal] = useState<TrackDownloadValues>({
    cover: '',
    mediaNetId: '',
    artist: '',
    title: '',
  })

  const numberOfPages = data?.numberOfPages ?? 10

  const mutationExportToCsv = useMutation<any, Error, any>(
    (body: any) => {
      return API.track.list({ ...body })
    },
    {
      onSuccess: (data) => {
        const pages = data.numberOfPages

        const csvColumns = [
          'MediaNet ID',
          'ISRC',
          'Song name',
          'Explicit',
          'Available countries',
          'Label',
          'Label owner',
          'Artist',
          'Album',
          'Genres',
          'Duration',
        ]

        const csvData = data.tracks.map((track: any) => [
          formatItemsForCsvData(track.trackId),
          formatItemsForCsvData(track.isrc),
          formatItemsForCsvData(track.title),
          track.parentalAdvisory ? 'Yes' : 'No',
          track.availableCountries.length === 0 ? '/' : track.availableCountries.map((e: any) => `${e} `),
          formatItemsForCsvData(track.label),
          formatItemsForCsvData(track.labelOwner),
          formatItemsForCsvData(track.artist),
          formatItemsForCsvData(track.albumName),
          formatItemsForCsvData(track.genre),
          Duration.fromISO(track.duration).as('seconds'),
        ])

        setCsvReport({
          data: csvData,
          headers: csvColumns,
          filename: 'ExportedList.csv',
        })

        csvLinkRef?.current?.link?.click()

        if (pages > 1) {
          notificationsStore.successNotification(
            'The search results are too broad. The exported list will contain the first 1000 results',
            6000,
          )

          setLoadingDataForCsv(false)
        } else {
          notificationsStore.successNotification('Tracks successfully exported')

          setLoadingDataForCsv(false)
        }
      },
    },
  )

  const handleChangePage = (pageNumber: number, recordsNumber: number) => {
    tracksStore.changePage(pageNumber, recordsNumber)
  }

  const handleSearch = (value: string) => {
    tracksStore.search(value)
  }

  // Table data
  const columns = [
    'Cover',
    'MediaNet ID',
    'ISRC',
    'Song name',
    'Explicit',
    'Available countries',
    'Label',
    'Label owner',
    'Artist',
    'Album',
    'Genres',
    'Duration',
    'Actions',
  ]

  const dataTable = tracks.map((track: Track) => [
    track.imageUrl,
    track.trackId,
    track.isrc,
    track.title,
    track.parentalAdvisory ? 'Yes' : 'No',
    track.availableCountries.length === 0 ? '/' : track.availableCountries.map((e) => `${e} `),
    track.label,
    track.labelOwner,
    track.artist,
    track.albumName,
    track.genre,
    Duration.fromISO(track.duration).toFormat('mm:ss'),
  ])

  useEffect(() => {
    const currentRowsIds = dataTable.map((item) => item[1])

    const containsAll = currentRowsIds.every((num) => selectedRows.includes(num))

    setMainTableCheckboxValue(containsAll)
  }, [data, selectedRows])

  const handleAddToPlaylistClick = () => {
    const tracksEmptyAvailableCountries = selectedRowsData
      .filter((item) => item.availableCountries.length === 0)
      .map((item) => item.trackId)

    if (tracksEmptyAvailableCountries.length !== 0) {
      notificationsStore.infoNotification(
        `Track(s) with Medianet ID ${tracksEmptyAvailableCountries}, 
        have not been added to the playlist. Reason - no country availability set`,
        6500,
      )

      return
    }

    setShowModal(true)
  }

  const handleDownloadTrackClick = (id: string) => {
    const track = tracks.filter((track) => track.trackId === id)
    const currentTrack = track[0]

    const helperObjDataForTrackDownloadModal: TrackDownloadValues = {
      cover: currentTrack.imageUrl,
      mediaNetId: currentTrack.trackId,
      artist: currentTrack.artist,
      title: currentTrack.title,
    }

    setDataForTrackDownloadModal(helperObjDataForTrackDownloadModal)
    setShowDownloadTrackModal(true)
  }

  const handleSelectClick = (id: string) => {
    setSelectedRowsData((prevSelected) => {
      const isSelected = prevSelected.find((item) => item.trackId === id)

      if (isSelected) {
        return prevSelected.filter((item) => item.trackId !== id)
      }

      const track = tracks.find((item) => item.trackId === id)

      return track ? [...prevSelected, track] : prevSelected
    })

    setSelectedRows((prevNumbers) =>
      prevNumbers.includes(id) ? prevNumbers.filter((currentItem) => currentItem !== id) : [...prevNumbers, id],
    )
  }

  const handleSelectAllClick = (event: any) => {
    const currentRowsIds = dataTable.map((item) => item[1])

    if (event.target.checked) {
      setSelectedRowsData((prevSelected) => {
        const filteredItems = tracks.filter((item) => currentRowsIds.includes(item.trackId))
        const updatedSelected = [...prevSelected]

        filteredItems.forEach((item) => {
          if (!updatedSelected.some((selectedItem) => selectedItem.trackId === item.trackId)) {
            updatedSelected.push(item)
          }
        })

        return updatedSelected
      })

      setSelectedRows((prevNumbers) => [...prevNumbers, ...currentRowsIds.filter((num) => !prevNumbers.includes(num))])
    } else {
      setSelectedRowsData((prevSelected) => {
        return prevSelected.filter((item) => !currentRowsIds.includes(item.trackId))
      })

      setSelectedRows((prevNumbers) => prevNumbers.filter((num) => !currentRowsIds.includes(num)))
    }

    const containsAll = currentRowsIds.every((num) => selectedRows.includes(num))

    setMainTableCheckboxValue(containsAll)
  }

  const handleCloseModal = () => {
    setShowModal(false)

    setDataForTrackDownloadModal({
      cover: '',
      mediaNetId: '',
      artist: '',
      title: '',
    })

    setShowDownloadTrackModal(false)
  }

  const handleExportToCsv = () => {
    setLoadingDataForCsv(true)

    const prepareIds = (values: Identity[]) => thread(values, [R.map(R.prop('id')), R.join(',')])
    const prepareCodes = (values: Identity[]) => thread(values, [R.map(R.prop('code')), R.join(',')])

    mutationExportToCsv.mutate({
      page: 1,
      size: 1000,
      title: tracksStore.filter,
      format: 'AAC',
      mediaNetIds: tracksStore.mediaNetIDFilter.toString(),
      isrcCodes: tracksStore.isrcFilter.toString(),
      genreIds: prepareIds(tracksStore.activeGenres),
      artistIds: prepareIds(tracksStore.activeArtists),
      albumIds: prepareIds(tracksStore.activeAlbums),
      territoryCodes: prepareCodes(tracksStore.activeCountries),
      labelIds: prepareIds(tracksStore.activeLabels),
      labelOwnerIds: prepareIds(tracksStore.activeLabelOwners),
      parentalAdvisory: tracksStore.parentalAdvisory,
    })
  }

  const footerClassName = cx(styles.footer, {
    [styles.open]: isOpen ? 'open' : '',
  })

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchBar
          hideResults
          label={Text.page.tracks.searchPlaceholder}
          filter={filter}
          onChangeFilter={setFilter}
          onSearch={handleSearch}
        />
      </div>
      <TrackFilter tracksStore={tracksStore} />
      {status === 'loading' ? (
        <Spinner />
      ) : (
        <React.Fragment>
          {tracks.length > 0 ? (
            <React.Fragment>
              <Table
                hideEdit
                hideDelete
                showDownloadTrack
                showCheckbox
                columns={columns}
                data={dataTable}
                selectedRows={selectedRows}
                mainTableCheckboxValue={mainTableCheckboxValue}
                onDownloadFile={handleDownloadTrackClick}
                onSelectClick={handleSelectClick}
                onSelectAllClick={handleSelectAllClick}
              />
              <If condition={tracks.length > 0}>
                <div className={styles.paginationContainer}>
                  <Pagination
                    size={tracksStore.pageSize}
                    page={tracksStore.page}
                    totalPages={numberOfPages}
                    onChange={handleChangePage}
                  />
                </div>
              </If>
              <div className={footerClassName}>
                {selectedRows.length > 0 && (
                  <div style={{ marginRight: 'auto' }}>{selectedRows.length} items selected </div>
                )}
                <CSVLink {...csvReport} ref={csvLinkRef} style={{ display: 'none' }} />
                <Button
                  disabled={selectedRows.length < 1}
                  data-test="add-to-playlist-button"
                  className={styles.button}
                  onClick={handleAddToPlaylistClick}
                >
                  {'ADD TO PLAYLIST'}
                </Button>
                <Button
                  data-test="submit-button"
                  loading={loadingDataForCsv}
                  className={styles.button}
                  onClick={handleExportToCsv}
                >
                  {'EXPORT TO CSV'}
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <p>There are no results that match your search</p>
          )}
        </React.Fragment>
      )}

      <TrackModal open={showModal} handleClose={handleCloseModal} trackMediaNetIds={selectedRows} />
      <DownloadTrackModal
        open={showDownloadTrackModal}
        handleClose={handleCloseModal}
        dataForTrackDownloadModal={dataForTrackDownloadModal}
      />
    </div>
  )
}

export default observer(Tracks)
