import React, { useEffect } from 'react'
import * as R from 'ramda'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import Text from 'locale/strings'

import { CompaniesRoute } from '../..'

import useStores from 'common/hook/useStore'
import { usePlatformListQuery } from '../../../platforms/PlatformsStore'

import { Toolbar, Typography, FormControl } from '@mui/material'

import Select from 'ui/Select/Select'
import Button, { ButtonVariant } from 'ui/Button/Button'
import TextField from 'ui/TextField/TextField'
import Spinner from 'ui/Spinner/Spinner'
import Footer from 'ui/Footer/Footer'

import { ApiStatuses } from 'common/constants'
import { OptionType } from 'common/api/common/common'
import API from 'common/api'
import { INITIAL_PAGE, ALL_RECORDS } from '../../../common/constants'
import { useCompanyQuery } from '../../CompaniesStore'
import { EditCompanyRequest, ErrCompanyResponse } from 'common/api/company/company'
import * as yup from 'yup'

import styles from './Company.module.scss'

const schema = yup.object({
  name: yup.string().required('Name is required').max(50, 'Name must be at most 50 characters'),
  platformId: yup.string().required('Platform is required'),
})

interface CompanyFormValues {
  id: string
  name: string
  platformId: string
}

const initialValues: CompanyFormValues = {
  id: '',
  name: '',
  platformId: '',
}

interface ParamProps {
  id?: string
}

const toPlatformsOptions = (item: any): OptionType => ({
  label: item.name,
  value: item.id,
})

const Company = () => {
  const { platformsStore, navigationStore, notificationsStore } = useStores()
  const { id = '' } = useParams<ParamProps>()
  const queryClient = useQueryClient()
  const { data: company } = useCompanyQuery(id)

  platformsStore.changePage(INITIAL_PAGE, ALL_RECORDS)

  const { data: platformsData, status: platformsStatus } = usePlatformListQuery(platformsStore)

  const platformsTypes = platformsData?.items ?? []
  const platformsOptions: OptionType[] = platformsTypes.map(toPlatformsOptions)

  const initialCompany = id !== '' ? company : null

  const mutationCompanyEvent = useMutation<any, ErrCompanyResponse, EditCompanyRequest>(
    (body: EditCompanyRequest) => {
      if (initialCompany) {
        return API.company.update({ ...body, companyId: initialCompany.id })
      }

      return API.company.create(body)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('companies')
        navigationStore.goToPage(CompaniesRoute.path)

        initialCompany
          ? notificationsStore.successNotification('Company successfully edited')
          : notificationsStore.successNotification('Company successfully added')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  useEffect(() => {
    if (platformsStatus === ApiStatuses.SUCCESS && company?.platform?.id) {
      setFieldValue('platformId', company?.platform.id)
    }
  }, [platformsStatus])

  const handleSubmit = async (values: CompanyFormValues) => {
    if (formik.dirty) {
      const body = {
        companyId: initialCompany?.id,
        name: values.name.trim(),
        platformId: values.platformId,
      }

      mutationCompanyEvent.mutate(body)
    }
  }

  const formik = useFormik<CompanyFormValues>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const { values, setFieldValue, resetForm } = formik

  useEffect(() => {
    if (!R.isNil(initialCompany)) {
      resetForm({
        values: {
          name: initialCompany.name,
          id: initialCompany.id,
          platformId: initialCompany?.platform?.id,
        },
      })
    }
  }, [initialCompany, resetForm])

  const handlePlatformChange = (platformOption: string) => {
    setFieldValue('platformId', platformOption)
  }

  return (
    <React.Fragment>
      {id !== '' && R.isNil(initialCompany) && platformsStatus === ApiStatuses.LOADING ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Toolbar className={styles.toolbar}>
            <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
              {!R.isNil(initialCompany) ? Text.navigation.editCompany : Text.navigation.createCompany}
            </Typography>
          </Toolbar>
          <div className={styles.container}>
            <div className={styles.form}>
              <TextField
                fullWidth
                data-test="name-field"
                label={Text.fields.name + ' *'}
                error={formik.errors.name}
                name="name"
                value={values.name}
                inputProps={{ maxLength: 50 }}
                onChange={formik.handleChange}
              />
              <FormControl sx={{ width: '100%' }}>
                <Select
                  fullWidth
                  data-test="platforms-field"
                  label={'Platforms'}
                  value={values.platformId}
                  options={platformsOptions}
                  className={styles.select}
                  error={formik.errors.platformId}
                  onChange={handlePlatformChange}
                />
              </FormControl>
            </div>
            <Footer>
              <Button
                data-test="cancel-button"
                variant={ButtonVariant.OUTLINED}
                onClick={() => navigationStore.goToPage(CompaniesRoute.path)}
              >
                {Text.common.cancel}
              </Button>
              <Button data-test="submit-button" loading={mutationCompanyEvent.isLoading} onClick={formik.handleSubmit}>
                {Text.common.save}
              </Button>
            </Footer>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default observer(Company)
