import strings from '../locale/strings'
import Route, { ParametrizedRoute } from 'lib/routing/Route'

import Platforms from './pages/Platforms'
import Platform from './pages/Platform/Platform'

import PaddingIcon from '@mui/icons-material/Padding'

export const PlatformsRoute: Route = {
  path: '/platforms',
  name: strings.navigation.platforms,
  exact: true,
  icon: <PaddingIcon />,
  component: Platforms,
}

export const PlatformCreateRoute: Route = {
  path: '/platforms/create',
  name: strings.navigation.platforms,
  exact: true,
  component: Platform,
}

export const PlatformEditRoute: ParametrizedRoute = {
  path: '/platforms/edit/:id',
  name: strings.navigation.editCompany,
  exact: true,
  component: Platform,
  getUrl: ({ id }) => `/platforms/edit/${id}`,
}
