import strings from '../locale/strings'
import Route from 'lib/routing/Route'

import Reports from './pages/Reports'

import AssessmentIcon from '@mui/icons-material/Assessment'

export const ReportsRoute: Route = {
  path: '/reporting',
  name: strings.navigation.reports,
  exact: true,
  icon: <AssessmentIcon />,
  component: Reports,
}
