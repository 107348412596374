import { createContext, useState, ReactNode, useContext, useEffect } from 'react'
import Storage from 'common/storage'

type SidebarContextType = {
  isOpen: boolean
  toggleSidebar: () => void
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined)

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const sidebarOnLoad = Storage.get('sidebarPosition') === 'open'

  const [isOpen, setIsOpen] = useState(sidebarOnLoad)

  useEffect(() => {
    Storage.set('sidebarPosition', isOpen ? 'open' : '')
  }, [isOpen])

  const toggleSidebar = () => {
    setIsOpen((prev) => !prev)
  }

  return <SidebarContext.Provider value={{ isOpen, toggleSidebar }}>{children}</SidebarContext.Provider>
}

export const useSidebar = () => {
  const context = useContext(SidebarContext)

  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider')
  }

  return context
}
