import Route from 'lib/routing/Route'
import strings from '../../locale/strings'
import NftsPage from './NftsPage'

import ViewColumnIcon from '@mui/icons-material/ViewColumn'

export const NftsRoute: Route = {
  path: '/nfts',
  name: strings.navigation.nfts,
  exact: true,
  icon: <ViewColumnIcon />,
  component: NftsPage,
}
