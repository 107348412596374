import Route from 'lib/routing/Route'
import TracksForRadioPage from './TracksForRadioPage'
import strings from '../../locale/strings'

import RadioIcon from '@mui/icons-material/Radio'

export const TracksForRadioRoute: Route = {
  path: '/tracks',
  name: strings.navigation.tracksForRadio,
  exact: true,
  icon: <RadioIcon />,
  component: TracksForRadioPage,
}
