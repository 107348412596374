import React, { useEffect } from 'react'
// import * as R from 'ramda'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { thread } from 'lib/fn/thread'

import { SidebarProvider } from 'utils/sidebarContext'
import UserProfileHOC from '../../user/UserProfileHOC'
import AuthHOC from '../../auth/AuthHOC'
import useStores from '../../common/hook/useStore'
import { LoginRoute } from '../../auth/pages/Login'
import Header from '../../ui/Header/Header'
import Drawer from '../../ui/Drawer/Drawer'
import Contnent from '../components/Content/Content'
import { AdminMode } from '../../common/constants'
import Storage from '../../common/storage'

import styles from './main.module.scss'

const MainLayout: React.FC = ({ children }) => {
  const history = useHistory()
  const { authStore, userStore, applicationStore } = useStores()

  const { companyId, appId, setAppId } = applicationStore

  useEffect(() => {
    const defaultApp = userStore.getCompanyApp(companyId)[0]?.id ?? ''

    setAppId(defaultApp)
  }, [companyId])

  const handleLogout = async () => {
    await authStore.logout()
    await applicationStore.setAdminMode(AdminMode.OFF)

    Storage.delete('adminMode')

    history.push(LoginRoute.path)
  }

  return (
    <SidebarProvider>
      <div className={styles.container}>
        <Header
          applications={userStore.getCompanyApp(companyId)}
          companies={userStore.getCompanies()}
          companyName={companyId}
          applicationName={appId}
          profileCredentials={userStore.getUserCredentials()}
          onLogout={handleLogout}
          onChangeApp={setAppId}
        />
        <Drawer />
        <Contnent>{children}</Contnent>
      </div>
    </SidebarProvider>
  )
}

export default thread(MainLayout, [observer, UserProfileHOC, AuthHOC])
